<template>
    <div>
        <app-page-header></app-page-header>
        <v-container grid-list-xl fluid>
            <v-layout row wrap>
                <v-flex xs12 sm12 md12 lg12>
                    <v-card>
                        <v-card-title>
                            <span class="subtitle-1"><v-icon left>mdi-account</v-icon> {{ user.name }}  <span v-if="user.email != null && user.email != ''" class="caption">({{ user.email }})</span> </span>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="toggleCardContent('info')">
                                <v-icon>{{ card2 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-divider v-show="card2"></v-divider>
                        <v-card-text v-show="card2">
                            <span class="body-2 font-weight-regular"><v-icon class="" medium color="secondary" left>mdi-information</v-icon> Z vašim uporabniškim računom lahko oddate več vlog za izdajo novih dovolilnic. Ko bo vaša vloga obdelana boste o obdelavi vloge obveščeni na vaš elektronski naslov, dovolilnica pa bo vidna tudi na seznamu vaših dovolilnic, ki jo boste lahko po potrebi zamenjali.</span>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>

            <div style="min-height:12px;"></div>
                <v-alert
                    border="right"
                    colored-border
                    color="secondary"
                    type="info"
                    elevation="1"
                >
                    Za podaljšanje ali spremembo dovolilnice kliknite na gumb <v-chip dark label small color="error"><v-icon left small >mdi-shuffle-variant</v-icon>Podaljšaj ali spremeni</v-chip> spodaj na seznamu vaših dovolilnic in podaljšajte željeno dovolilnico.
                </v-alert>
            <div style="min-height:12px;"></div>
            <user-permits-data-table
            :settings="permitsDataTableSettings"
            :queryParameters="permitsQueryParameters"
            :boxedLayout="false"
            ></user-permits-data-table>

            <v-layout row wrap>
                <v-flex xs12 sm12 md12 lg12>
                    <v-card>
                        <v-card-title>
                                <span class="subtitle-1">Seznam vlog za izdajo novih dovolilnic </span>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('dovolilnice')">
                                    <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>
                        </v-card-title>
                        <v-divider v-show="card1"></v-divider>
                        <v-card-text v-show="card1" v-if="$vuetify.breakpoint.smAndDown">
                            <v-layout row wrap align-center justify-center fill height v-for="(item, index) in permitItems" :key="index">
                                <v-flex xs12>
                                    <span class="subtitle-1">
                                        <v-icon left>{{ item.icon }}</v-icon> 
                                        {{ item.title }}
                                    </span>   
                                </v-flex>
                                <v-flex xs12>
                                    <v-btn dark color="secondary" small @click="goToRoute(item.routeName)" block outlined>
                                        <v-icon small left>mdi-pencil</v-icon>
                                        Nova vloga
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                        </v-card-text>
                        <v-card-text v-show="card1" v-else>
                            <v-list>
                                <v-list-item
                                    v-for="item in permitItems"
                                    :key="item.title"
                                    @click="goToRoute(item.routeName)"
                                    >
                                        <v-list-item-icon>
                                            <v-icon color="">{{ item.icon }}</v-icon>
                                        </v-list-item-icon>
                                
                                        <v-list-item-content>
                                            <v-list-item-title class="body-2" v-text="item.title"></v-list-item-title>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-btn dark color="secondary" small>
                                                <v-icon small left>mdi-pencil</v-icon>
                                                Nova vloga
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>

            <div style="min-height:12px;"></div>

            <user-applications-data-table
            :settings="applicationsDataTableSettings"
            :queryParameters="applicationsQueryParameters"
            :boxedLayout="false"
            ></user-applications-data-table> 

            <data-table-toggle-columns
            :dataTableId="applicationsDataTableSettings.id"
            :columns="applicationsDataTableSettings.headers"
            :dataTableColumnsMenuId="applicationsDataTableSettings.eventBus.toggleColumnsId"

            ></data-table-toggle-columns>

            <data-table-toggle-columns
            :dataTableId=" permitsDataTableSettings.id"
            :columns=" permitsDataTableSettings.headers"
            :dataTableColumnsMenuId=" permitsDataTableSettings.eventBus.toggleColumnsId"

            ></data-table-toggle-columns>
        </v-container>
    </div>
</template>

<script>
const UserApplicationsDataTable = () => import('@/components/user/UserApplicationsDataTable.vue')
const UserPermitsDataTable = () => import('@/components/user/UserPermitsDataTable.vue')
const SetUserAccountData = () => import('@/components/SetUserAccountData.vue')
const DataTableToggleColumns = () => import('@/components/DataTableToggleColumns.vue')

export default {
    components: {
        UserApplicationsDataTable,
        UserPermitsDataTable,
        DataTableToggleColumns,
    },
    name: 'user-dashboard',
    data: () => ({
        card1: true,
        card2: true,
        permitItems: [
            {
                title: 'Parkirna dovolilnica (Blejska kartica)',
                mobile_title: 'Vloga za izdajo dovolilnice za dostop na Blejsko promenado',
                icon: 'mdi-parking',
                routeName: 'user_parking_application'
            },
            {
                title: 'Vloga za izdajo dovolilnice za dostop na blejsko promenado',
                mobile_title: 'Vloga za izdajo dovolilnice za dostop na blejsko promenado',
                icon: 'mdi-city-variant-outline',
                routeName: 'user_promenada_application'
            }
        ],

        pwResetAlert: false,
        emailSetAlert: false,

        permitsDataTableSettings: {
            id: 'dtUserPermitsDataTable',
            title: 'Seznam vaših dovolilnic',
            sortColumn: ['id'],
            sortDirection: ['desc'],
            progressHeight: 3,
            headers: [
                { text: "ID", value: "id", align: "left", visibility: true},
                { text: "Ime in priimek", value: "name", visibility: true},
                /*{ text: "Naslov", value: "user.address", visibility: true},
                { text: "Poštna št.", value: "user.postal_code", visibility: true},
                { text: "Kraj", value: "user.town", visibility: true},*/
                { text: "Datum vpisa", value: "created_at", visibility: true},
                { text: "Datum veljavnosti", value: "restriction_end_date_formatted", visibility: true},
                { text: "Tip dovolilnice", value: "permit_type", visibility: true},
                { text: "Št. vloge", value: "application_id", visibility: true},
                { text: "Status", value: "permit_status", visibility: true},
                { text: "Upravljanje", value: "action", align: "right", sortable: false, visibility: true}
            ],
            updateInterval: false,
            rowsPerPage: 10,
            endpoint: 'v1/user/permits',
            editItem: {
                route: null,
                routeParameterValue: null
            },
            deleteItem: {
                vuexAction: null,
                replaceStrWithColumnValue: null,
                confirmationText : null,
                dialogConfirmSuccessText: null,
                dialogConfirmFailText: null
            },

            toolbar: {
                buttons: {
                    refresh: true,
                    export: false,
                    addNew: false,
                    addNewRoute: '',
                    toggleColumns: true
                }
            },

            eventBus: {
                refreshDataTable: 'refresh_dt_user_permits_user',
                totalItems : {
                    event: ''
                },
                search: 'dt-user-permits-search-user',
                toggleColumnsId: 'toggle-user-permits-list-columns-user'
            }


        },

        applicationsDataTableSettings: {
            id: 'dtUserApplicationsDataTable',
            title: 'Seznam vaših vlog',
            sortColumn: ['id'],
            sortDirection: ['desc'],
            progressHeight: 3,
            headers: [
                { text: "ID", value: "id", align: "left", visibility: true},
                { text: "Ime in priimek", value: "name", visibility: true},
                /*{ text: "Naslov", value: "user.address", visibility: true},
                { text: "Poštna št.", value: "user.postal_code", visibility: true},
                { text: "Kraj", value: "user.town", visibility: true},*/
                { text: "Datum vpisa", value: "created_at", visibility: true},
                { text: "Tip vloge", value: "permit_type", visibility: true},
                { text: "Status vloge", value: "application_status", visibility: true},
                { text: "Status plačila", value: "payment_status", visibility: true},
                { text: "Upravljanje", value: "action", align: "right", sortable: false, visibility: true}
            ],
            updateInterval: false,
            rowsPerPage: 10,
            endpoint: 'v1/user/applications',
            editItem: {
                route: null,
                routeParameterValue: null
            },
            deleteItem: {
                vuexAction: null,
                replaceStrWithColumnValue: null,
                confirmationText : null,
                dialogConfirmSuccessText: null,
                dialogConfirmFailText: null
            },

            toolbar: {
                buttons: {
                    refresh: true,
                    export: false,
                    addNew: false,
                    addNewRoute: '',
                    toggleColumns: true
                }
            },

            eventBus: {
                refreshDataTable: 'refresh_dt_user_applications_user',
                totalItems : {
                    event: ''
                },
                search: 'dt-user-applications-search-user',
                toggleColumnsId: 'toggle-user-applications-list-columns-user'
            }


        }
    }),

    computed: {
        applicationsQueryParameters() {
            return {
            }
        },

        permitsQueryParameters() {
            return {
            }
        },

        user() {
            return this.$store.getters.user
        }, 
    },

    methods: {
        toggleCardContent(card) {
            if(card === 'dovolilnice') {
                this.card1 = !this.card1
            }

            if(card === 'info') {
                this.card2 = !this.card2
            }
        },

        goToRoute(routeName) {
            this.$router.push({
                name: routeName
            })
        },

        checkPwResetFlag() {
            let user = this.$store.getters.user 
            if(user.hasOwnProperty('reset')) {
                if(user.reset != 1) {
                    this.pwResetAlert = true
                    return;
                }
            }

            this.pwResetAlert = false
        },

        checkEmailSetFlag() {
            let user = this.$store.getters.user 
            if(user.hasOwnProperty('email')) {
                if(user.email == null || user.email == "") {
                    this.emailSetAlert = true
                    return;
                }
            }

            this.emailSetAlert = false
        }
    },

    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name)
    },

    mounted() {
        this.checkPwResetFlag();
        this.checkEmailSetFlag();
    }
}
</script>

<style scoped>
    .v-card__text {
        color: rgba(0,0,0,0.90) !important;
    }

    .dialog_card_title {
        background-color: #F5F5F5 !important;
    }
</style>